<template>
  <EmTooltip bottom :disabled="!tooltipMessage">
    <template #activator="{ props: slotProps }">
      <span v-bind="slotProps">
        <v-avatar
          :size="small ? '32' : '45'"
          v-bind="slotProps"
          :color="avatarColorFromNumber()"
        >
          <slot name="icon">
            <EmIcon v-if="!name" icon="mdi-phone" size="small" color="white" />
            <EmTinyText v-else-if="small" class="text-white" bold>
              {{ nameAbbreviation }}
              <slot />
            </EmTinyText>
            <EmBody3 v-else class="text-white" bold>
              {{ nameAbbreviation }}
              <slot />
            </EmBody3>
          </slot>
        </v-avatar>
      </span>
    </template>
    <span>{{ tooltipMessage }}</span>
  </EmTooltip>
</template>
<script setup>
const props = defineProps({
  phoneNumber: {
    type: [Number, String],
    required: true
  },
  name: {
    type: String,
    default: null
  },
  small: {
    type: Boolean,
    default: false
  },
  tooltipMessage: {
    type: String,
    default: null
  }
})
const EMAIL_REGEX = /.+@.+\..+/
const nameIsEmail = computed(() => {
  return EMAIL_REGEX.test(props.name)
})
const nameAbbreviation = computed(() => {
  return nameIsEmail.value
    ? `${props.name[0].toUpperCase()}@`
    : props.name.replace(/[^a-zA-Z0-9\s]/g, '')
      .trim()
      .split(' ')
      .map(word => word[0])
      .join('')
      .substring(0, 2)
      .toUpperCase()
})
const avatarColorFromNumber = () => {
  const numberStr = props.phoneNumber?.toString() || ''
  let hash = 0
  for (let i = 0; i < numberStr.length; i++) {
    hash = numberStr.charCodeAt(i) + hash * 31 // Prime numbers produce unique results and the likeliness of them producing the same value is really low
  }
  return `hsla(${Math.floor(360 * hash)},40%,30%)`
}
</script>
